import React, { useCallback } from 'react';
import { IDocumentDetails, FILE_STATUS } from '../@types/models';
import SpinnerComponent from '../common/components/controls/spinner';
import {
    makeStyles,
    useId,
    Toaster,
    useToastController,
    ToastIntent
} from '@fluentui/react-components';

import { t } from "i18next";
import { getArchiveHome, getDocumentDetailComp } from '../common/components/home/HomeComponents';
import { getToastMessageComponent } from '../common/components/ToastMessage';
import { getEnvironment360URL } from '../services/configs/appConfig';
import { getFileInfoFromCheckedOut, getFileInfoFromDocRecno } from '../services/api/dataServiceOffice';

const useStyles = makeStyles({
    containerbody:
    {
        width: "100%",
        minHeight: "inherit",
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },

});

export const OfficeHome: React.FC = () => {
    const loadingText = t("Loading...");
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loadingStatus, setLoadingStatus] = React.useState<FILE_STATUS>(FILE_STATUS.NOT_EXISTS);
    const [documentInfo, setDocumentInfo] = React.useState<IDocumentDetails>();
    const styles = useStyles();
    const toasterId = useId("toastIdHome");
    const { dispatchToast, dismissToast } = useToastController(toasterId);


    const showToast = (message: string, toastType: ToastIntent) => {
        setTimeout(() => {
            dispatchToast(
                getToastMessageComponent(message, () => checkCaseExists()),
                { intent: toastType }
            );
        }, 100); // Small delay of 100ms
    };

    const checkCaseExists = React.useCallback(() => {
        dismissToast(toasterId);
        setLoadingStatus(FILE_STATUS.NOT_EXISTS);
        setShowSpinner(true);
        const docRecno = new URLSearchParams(window.location.search).get("docRecno");
        const prereq = getPrerequisiteData();

        function successCB(resData: IDocumentDetails) {
            setShowSpinner(false);
            if (resData === null) {
                setLoadingStatus(FILE_STATUS.NOT_EXISTS);
                setShowSpinner(false);
            } else {
                setDocumentInfo(resData);
                setLoadingStatus(FILE_STATUS.EXISTS);
                setShowSpinner(false);
            }
        }
        function errorCB(err: string) {
            setShowSpinner(false);
            showToast(err, "error");
        }

        try {
            if (docRecno) {
                void getFileInfoFromDocRecno(docRecno, successCB, errorCB);
            } else if (prereq?.fileId) {
                void getFileInfoFromCheckedOut(prereq.fileId, successCB, errorCB);
            } else {
                setLoadingStatus(FILE_STATUS.NOT_EXISTS);
                setShowSpinner(false);
            }

        } catch (exception: unknown) {
            setShowSpinner(false);
            setShowSpinner(false);
            showToast(exception as string, "error");
        }


    }, [dismissToast, toasterId]);

    const getPrerequisiteData = useCallback(() => {

        if (Office.context.document?.url) {
            const docUrl = Office.context.document.url;
            const fileName = getfileNameFromUrl(docUrl);
            const wordArray = fileName ? fileName.split(" ") : [];
            const lastWord = wordArray.pop();
            const fileId = lastWord ? lastWord.split("_")[0] : null;

            return {
                fileId: isNaN(Number(fileId)) ? null : Number(fileId),
                fileName: fileName ?? '',
            };
        } else {
            return null;
        }
    }, []);

    React.useEffect(() => {

        if (getEnvironment360URL()) {
            checkCaseExists();
        } else {
            setErrorMsg(t("The 360 url not set correctly"));
            setLoadingStatus(FILE_STATUS.ERROR);
        }

    }, [checkCaseExists]);


    let uiToDisplay;
    if (loadingStatus === FILE_STATUS.ERROR) {
        showToast(errorMsg, "error");
        uiToDisplay = getArchiveHome();
        // Could use a mew message/component to show after the firt try failed. Showing the home page for now instead of blank page
    } else if (loadingStatus === FILE_STATUS.EXISTS && documentInfo) {
        uiToDisplay = getDocumentDetailComp(documentInfo);
    } else if (loadingStatus === FILE_STATUS.NOT_EXISTS) {
        uiToDisplay = getArchiveHome();
    }
    return (
        <div className={styles.containerbody}>
            {uiToDisplay}
            <Toaster toasterId={toasterId} />
            <SpinnerComponent showSpinner={showSpinner} size='medium' label={loadingText} labelPosition='after' />
        </div>
    );
};
export default OfficeHome;

function getfileNameFromUrl(docUrl: string): string | null {
    const urlParts = docUrl.split('/');
    return urlParts.length > 0 ? urlParts[urlParts.length - 1] : null;
}

