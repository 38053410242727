import React from 'react';
import { FluentProvider, makeStyles, mergeClasses } from '@fluentui/react-components';
import { ThemeProvider } from './contexts/theme/ThemeProvider';
import useTheme from './contexts/theme/useTheme';
import { BrowserRouter } from 'react-router-dom';
import Header from './common/components/Header';
import { routes } from './routes';
import { isValidDomain } from './common/components/ValidDomain';
import { t } from "i18next";
import { isOutlookApp } from './utils/Helper';

const useStyles = makeStyles({
    root:
    {
        minWidth: "280px",
        maxWidth: "320px",
        minHeight: "579px"
    },
    contents: {
        justifyContent: "space-between"
    }
});

const App: React.FC = () => {
    const { theme } = useTheme();
    const styles = useStyles();
    const public360Url = new URLSearchParams(window.location.search).get("url");

    if (public360Url && isValidDomain(public360Url)) {
        localStorage.setItem("public360Url", public360Url ?? "");
    }
    else {
        return <p>{t('No Valid Domain found')}</p>;
    }
    const rootStyle = mergeClasses(isOutlookApp() ? styles.root : '', styles.contents);
    return (
        <FluentProvider theme={theme} className={rootStyle}>
            <BrowserRouter>
                <Header>
                    {routes}
                </Header>
            </BrowserRouter>
        </FluentProvider>
    );
};

const AppWrapper: React.FC = () => {
    return (
        <ThemeProvider>
            <App />
        </ThemeProvider>
    );
};

export default AppWrapper;
