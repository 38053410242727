import { getEnvironment360URL, getFetchApiOptions } from "../configs/appConfig";
import { getHeaders } from "./dataService";
import { uploadAndGetRecno } from "./dataServiceOffice";

type ProgressCallback = (bytesComplete: number, percentComplete: number) => void;

const numRetries = 3; // Number of retries for each chunk in case of failure

export async function ChunkedFileUploadHandler(file: Office.File, fileName: string,
    sCB: (res: string) => void, progressCallback?: ProgressCallback, abortController?: AbortController) {


    const headers = await getHeaders();

    const start = 0;
    const initUrl = getEnvironment360URL() + "/Biz/v2/support/chunkupload/initupload?filename=" + encodeURIComponent(fileName);

    const token = await (await fetch(initUrl, {
        method: 'POST',
        headers: headers,
        signal: abortController?.signal,
        ...getFetchApiOptions,
    })).text();
    const uploadUrl = getEnvironment360URL() + "/Biz/v2/support/chunkupload/uploadchunk?token=" + encodeURIComponent(token);
    const finalizeUrl = getEnvironment360URL() + "/Biz/v2/support/chunkupload/finalizeupload?token=" + encodeURIComponent(token);
    progressCallback && progressCallback(0, 0);


    if (abortController?.signal.aborted) throw new DOMException("File upload aborted", "AbortError");
    const sliceCount = file.sliceCount;
    let sliceIndex = 0;


    const getSlice = function () { // Recursive function to get slices of the file{
        file.getSliceAsync(sliceIndex,
            function (asyncResult: { status: Office.AsyncResultStatus; value: { data: ConcatArray<never>; }; error: { message: string; }; }) {
                if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                    void uploadChunk(new Uint8Array(asyncResult.value.data), uploadUrl, numRetries, headers, abortController).then(async () => {
                        progressCallback && progressCallback(Math.min(start, file.size), Math.min(100 * start / file.size, 100));
                        sliceIndex++;
                        if (sliceIndex === sliceCount) {
                            await completeUpload();
                            sCB(token);
                            file.closeAsync();
                        } else {
                            getSlice();
                        }
                    });
                }
                else {
                    file.closeAsync();
                }
            });

    };

    getSlice();

    const completeUpload = async () => {
        await fetch(finalizeUrl, {
            method: 'POST',
            headers: headers,
            signal: abortController?.signal,
            ...getFetchApiOptions
        });
    };


}

async function uploadChunk(chunk: Uint8Array, url: string, retries: number, headers: Record<string, string>, abortController?: AbortController) {
    try {
        await fetch(url, {
            method: 'POST',
            body: chunk,
            headers: headers,
            signal: abortController?.signal,
            ...getFetchApiOptions
        });
    } catch (err) {
        if (retries > 0) {
            await uploadChunk(chunk, url, retries - 1, headers);
        } else {
            console.log('Failed to upload chunk: ', err);
            throw err;
        }
    }
}

export const UploadChunkService = {
    uploadtheFileandGetRecno: (fileName: string, successCb: (resData: string | null) => void, errorCb: (err: string) => void) => {
        const controller = new AbortController();

        Office.context.document.getFileAsync(Office.FileType.Compressed, function (asyncResult) { //{ sliceSize: 10240 },
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                errorCb(asyncResult.error.message);
            }
            else {

                void ChunkedFileUploadHandler(asyncResult.value, fileName, (fileToken: string) => {
                    void uploadAndGetRecno(fileToken, fileName, successCb);
                }, undefined, controller);
            }

        });


    }
};